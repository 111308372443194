<template>
    <comp-table title="明细" ref="comp_table" :columns="columns" :dataList="dataList">
        <template slot="operate">
            <Button type="primary" style="margin-right: 10px" @click="exportExcel">导出</Button>
        </template>
    </comp-table>
</template>

<script>
import CompTable from "../jointly/components/comp-table.vue"

export default {
    components: { CompTable },

    data() {
        return {
            columns: [
                {
                    title: "日期",
                    key: "statDate",
                },
                {
                    title: "机构数",
                    key: "count",
                },
                {
                    title: "操作",
                    fixed: "right",
                    minWidth: 150,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            console.log(params.row)

                                            if (this.monthValue == "0") {
                                                // 按月
                                                window.sessionStorage.setItem("detailList3", JSON.stringify(params.row.dataList))
                                            } else {
                                                // 按年
                                                window.sessionStorage.setItem("detailList3", JSON.stringify(this.dataList2[params.row.index]))
                                            }
                                            this.$core.openLayerFrame(
                                                {
                                                    type: 2,
                                                    title: "明细",
                                                    content: `/page#/sendviewdetail?monthValue=${this.monthValue}&time=${params.row.statDate}`,
                                                    area: ["100%", "100%"],
                                                },
                                                () => {
                                                    window.sessionStorage.removeItem("detailList3")
                                                }
                                            )
                                        },
                                    },
                                },
                                "明细"
                            ),
                        ])
                    },
                },
            ],
            dataList: "",
            monthValue: 0, //按月
            dataList2: [],
        }
    },

    mounted() {
        this.monthValue = this.$route.query.monthValue
        if (this.monthValue == "0") {
            // 按月
            this.dataList = JSON.parse(window.sessionStorage.getItem("detailList")).sort((a, b) => new Date(a.statDate) - new Date(b.statDate));
        } else {
            // 按年
            this.dataList2 = Array.from(Object.values(JSON.parse(window.sessionStorage.getItem("detailList2"))), x => x)
            this.dataList = Array.from(Object.values(JSON.parse(window.sessionStorage.getItem("detailList"))), x => x).map((item, index) => {
                return {
                    statDate: this.$route.query.year + (index + 1) + "月",
                    count: item,
                    index,
                }
            })
        }
    },

    methods: {
        exportExcel() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在导出当前页的数据，是否继续？</p>",
                onOk: () => {
                    let tHeader = ["日期", "机构数"]
                    let filterVal = ["statDate", "count"]
                    let tableData = JSON.parse(JSON.stringify(this.dataList))
                    this.$core.exportExcel(tHeader, filterVal, tableData, "导出明细")
                },
            })
        },
    },
}
</script>
<style lang="less"></style>
